'use client';

import Footer from '@/containers/Footer';
import { Box } from '@mui/material';

export default function HomeLayout({ children }: { children: React.ReactNode }) {
  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: '100vh',
        background: "url('/images/home_bg.webp')",
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {children}
      <Footer />
    </Box>
  );
}

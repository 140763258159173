'use client';

import { useRef, useState } from 'react';
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  InputBase,
  InputBaseProps,
  MenuItem,
  Select,
  useMediaQuery,
} from '@mui/material';
import { KeyboardArrowDown, Search } from '@mui/icons-material';

import ClearSVG from '@/assets/images/clear-icon.svg';
import SearchSVG from '@/assets/images/search-icon.svg';
import { SearchType } from '@/services/ethscriptions/types';

interface ISearchInput {
  searchType: SearchType;
  onClear: () => void;
  onClick: (val: string) => void;
  onEnter: (val: string) => void;
  onSearchTypeChage: (e: SearchType) => void;
  searchBy: string;
  onSearchByChange: (e: string) => void;
}

type Condition = {
  label: string;
  value: string;
};

const MutipleTypeSearchInput: React.FC<ISearchInput & InputBaseProps> = ({
  onClick,
  onClear,
  onEnter,
  sx,
  searchType,
  onSearchTypeChage,
  searchBy,
  onSearchByChange,
}) => {
  const inputRef = useRef<HTMLInputElement>();
  const [isClear, setIsClear] = useState<boolean>(false);
  const isMobile = useMediaQuery('(max-width:750px)');
  const conditionArr: Condition[] = [
    {
      label: 'All Type',
      value: 'all',
    },
    {
      label: 'Text',
      value: 'text',
    },
    {
      label: 'Domain',
      value: 'domain',
    },
    {
      label: 'Token',
      value: 'token',
    },
  ];

  return (
    <Box
      sx={{
        p: '0',
        display: 'flex',
        alignItems: 'center',
        width: isMobile ? '90vw' : 640,
        height: 52,
        borderRadius: '6px',
        border: '1px solid #2F343E',
        background: '#202229',
        transition: 'all 0.2s ease-in-out',
        ':focus-within': {
          border: '1px solid #E5FF65',
        },
        ...sx,
      }}
    >
      <InputBase
        sx={{ flex: 1, fontSize: '14px' }}
        inputRef={inputRef}
        placeholder="Search by Address / Ethscription / Token / Domain"
        value={searchBy}
        startAdornment={
          <InputAdornment position="start">
            <Select
              IconComponent={KeyboardArrowDown}
              MenuProps={{
                sx: {
                  '& .MuiMenu-paper': {
                    backgroundColor: '#313439',
                  },
                },
              }}
              sx={{
                color: 'white',
                fontSize: 14,
                fontWeight: '500',
                '.MuiSvgIcon-root': { color: '#BFBFBF' },
                '.MuiOutlinedInput-notchedOutline': { border: 'none' },
              }}
              defaultValue=""
              value={searchType}
              onChange={(e) => {
                onSearchTypeChage(e.target.value as SearchType);
              }}
            >
              {conditionArr.map((item) => (
                <MenuItem
                  key={item.value}
                  sx={{
                    color: 'rgba(255,255,255,0.65)',
                    fontSize: '14px',
                    '&.Mui-selected': {
                      backgroundColor: 'transparent',
                      color: 'white',
                    },
                    '&.Mui-selected:hover': {
                      backgroundColor: 'transparent',
                      color: 'white',
                    },
                  }}
                  value={item.value}
                >
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <Divider orientation="vertical" sx={{ height: '16px' }} />
          </InputAdornment>
        }
        onChange={(e) => {
          onSearchByChange(e.target.value);
        }}
        onInput={(event) => {
          const _value = inputRef.current?.value.trim() ?? '';
          if (_value != '') {
            setIsClear(true);
          } else {
            setIsClear(false);
            onClear();
          }
        }}
        onKeyUp={(event) => {
          const _value = inputRef.current?.value.trim() ?? '';
          if (event.key === 'Enter' && _value != '') {
            onEnter(_value);
          }
        }}
      />

      {isClear && (
        <IconButton
          type="button"
          sx={{ p: '10px' }}
          onClick={() => {
            if (inputRef.current) {
              inputRef.current.value = '';
              setIsClear(false);
              onClear();
            }
          }}
        >
          <ClearSVG />
        </IconButton>
      )}

      <Divider sx={{ height: '100%' }} orientation="vertical" />
      <IconButton
        color="primary"
        sx={{
          width: '50px',
          height: '100%',
          borderRadius: '0',
          borderTopRightRadius: '6px',
          borderBottomRightRadius: '6px',
          '&:hover': {
            background: '#E5FF65',
            svg: {
              fill: '#171A1F',
            },
          },
        }}
        aria-label="directions"
        onClick={() => {
          const _value = inputRef.current?.value.trim() ?? '';
          onClick(_value);
        }}
      >
        <SearchSVG className="icon" />
      </IconButton>
    </Box>
  );
};

export default MutipleTypeSearchInput;

'use client';

import { Box, Typography, useMediaQuery, Grid } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';

import HomeLayout from './HomeLayout';
import MutipleTypeSearchInput from '@/components/MutipleTypeSearchInput';
import RecentEthsciptionList from '@/containers/RecentEthsciptionList';
import LatestTransactionList from '@/containers/LatestTransactionList';
import { useRouter } from 'next/navigation';
import { SearchType } from '@/services/ethscriptions/types';

const Home = () => {
  const [searchType, setSearchType] = useState<SearchType>('all');
  const [searchBy, setSearchBy] = useState('');
  const router = useRouter();

  const onclickSearch = () => {
    router.push(`/search?searchType=${searchType}&searchBy=${searchBy}`);
  };
  const onSearchTypeChange = (e: SearchType) => {
    setSearchType(e);
  };

  return (
    <HomeLayout>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          px: { xs: '10px', sm: '140px' },
          pb: '110px',
          pt: '115px',
        }}
      >
        <Typography
          sx={{
            color: '#E6FF65',
            fontSize: '20px',
            fontWeight: '600',
            mb: '16px',
            textAlign: 'center',
          }}
        >
          The Ethscriptions Explorer
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="center" mb="108px">
          <MutipleTypeSearchInput
            onClear={() => {
              setSearchBy('');
            }}
            searchType={searchType}
            searchBy={searchBy}
            onSearchByChange={(e: string) => {
              setSearchBy(e);
            }}
            onSearchTypeChage={onSearchTypeChange}
            onClick={onclickSearch}
            onEnter={onclickSearch}
          />
        </Box>
        <Grid container spacing={3} sx={{ maxWidth: '1400px' }}>
          <Grid item lg={6} xs={12}>
            <RecentEthsciptionList />
          </Grid>
          <Grid item lg={6} xs={12}>
            <LatestTransactionList />
          </Grid>
        </Grid>
        {/* <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '24px',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <RecentEthsciptionList />
          <LatestTransactionList />
        </Box> */}
      </Box>
    </HomeLayout>
  );
};

export default Home;
